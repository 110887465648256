import { type AxiosResponse } from 'axios';
import { useMutation, useSuspenseQuery } from '@tanstack/react-query';

import { APIError, ResourceWithId } from '@api/types';
import { post, get, put } from '@api/httpClient';
import { type ProjectListResponse } from '@api/project/types';
import queryClient from '@api/queryClient';
import {
  ExperimentEditSchema,
  ProjectEditSchema,
  ProjectSchema,
  type ProjectSchemaID,
} from '@/zod_schemas';

const QUERY_KEYS = {
  PROJECTS: ['projects'],
  PROJECT: (projectId: string) => ['projects', { id: projectId }],
  PROJECTS_EXP_GROUP: ['projects_exp_group'],
  PROJECT_EXP_GROUP: (projectId: string) => ['projects', { id: projectId }],
};

export const useCreateProject = () =>
  useMutation<AxiosResponse<ProjectSchemaID>, APIError, ProjectSchemaID>({
    mutationFn: (project) =>
      post<ProjectSchemaID>('/projects/', { ...project }),
  });

export const useCreateProjectSample = () =>
  useMutation<AxiosResponse<ProjectSchemaID>, APIError, ProjectSchemaID>({
    mutationFn: (project) =>
      post<ProjectSchemaID>('/projects/experiment_sample/', { ...project }),
  });

export const useUpdateProject = () =>
  useMutation<
    AxiosResponse<ProjectSchemaID>,
    APIError,
    ResourceWithId<ProjectSchemaID>
  >({
    mutationFn: ({ id, payload }) =>
      put<ProjectSchemaID>(`/projects/${id}/`, { ...payload }),
    onSuccess: ({ data: { id } }) => {
      if (!id) return;

      queryClient.invalidateQueries({
        queryKey: QUERY_KEYS.PROJECTS,
      });
    },
  });

export const useUpdateEditProject = () =>
  useMutation<
    AxiosResponse<ProjectEditSchema>,
    APIError,
    ResourceWithId<ProjectEditSchema>
  >({
    mutationFn: ({ id, payload }) =>
      put<ProjectEditSchema>(`/projects/${id}/`, { ...payload }),
    onSuccess: ({ data: { id } }) => {
      if (!id) return;

      queryClient.invalidateQueries({
        queryKey: QUERY_KEYS.PROJECTS,
      });
    },
  });

export const useListProjects = () =>
  useSuspenseQuery<ProjectListResponse>({
    queryKey: QUERY_KEYS.PROJECTS,
    queryFn: async () => {
      const resp = await get<ProjectListResponse>('/projects/');
      return resp.data;
    },
  });

export const useGetProject = (projectId?: string) =>
  useSuspenseQuery<ProjectSchema>({
    queryKey: QUERY_KEYS.PROJECT(projectId || ''),
    queryFn: async () => {
      if (!projectId) return {};

      const resp = await get(`/projects/${projectId}/`);
      return resp.data;
    },
  });

const QUERY_KEYS2 = {
  PROJECTS_EXP_GROUP: ['projects_exp_group'],
  PROJECT_EXP_GROUP: (projectId: string) => ['projects', { id: projectId }],
};

export const useGetProjectExperimentGroups = (projectId?: string) =>
  useSuspenseQuery<ProjectEditSchema>({
    queryKey: QUERY_KEYS2.PROJECT_EXP_GROUP(projectId || ''),
    queryFn: async () => {
      if (!projectId) return {};

      const resp = await get(`/projects/${projectId}/experiment_group/`);
      return resp.data;
    },
  });

export const useGetExperimentsGroupSamples = (groupId?: string) =>
  useSuspenseQuery<ExperimentEditSchema>({
    queryKey: QUERY_KEYS.PROJECT(groupId || ''),
    queryFn: async () => {
      if (!groupId) return {};

      const resp = await get(`/experiment_group/${groupId}/detailed/`);
      return resp.data;
    },
  });
